<template>
  <div class="qui-sommes-nous">
    <div class="container d-flex flex-column flex-lg-row p-0">
      <div :class="'header-qui-sommes-nous px-0 col-lg-5 p-lg-0'">
        <img src="../assets/img/herve1.png" alt=""/>
        <div class="d-block d-lg-none bg-danger">
          <h2 class="text-white">Hervé Dechene</h2>
        </div>
      </div>

      <div :class="'pt-5 px-0 pt-lg-0 col-lg-8 position-relative my-auto'">
        <div class="d-none d-lg-block col-lg-10 m-4">
          <h2 class="h2-fat text-danger">Hervé Dechene</h2>
        </div>
        <div :class="'container col-lg-10 m-lg-4'">
          <TitleTextButton
            :other="true"
            :title="true"
            :text="true"
            :button="false"
            otherContent='<p class="subtitle text-uppercase component-other">Présentation</p>'
            titleContent='Développement entrepreneurial <br>& Open innovation'
            textContent="Hervé Dechene est diplômé de l'Executive MBA HEC, de l'Institut Supérieur de Marketing Vente et ingénieur en informatique. Depuis 2016, il est Auditeur de <a href='https://www.ihedn.fr' target='_blank'>L'IHEDN</a>."
            otherClass="text-dark"
            titleClass="presentation-dev-title"
            textClass="presentation-dev-text pr-5"
          />
          <div class="mt-4 d-none d-lg-flex">
            <router-link to="/contact" class="btn bg-danger mr-2">Contactez-nous</router-link>
            <a href="https://www.linkedin.com/in/hdechene/" class="btn bg-info ml-2" target="_blank">Retrouvez-le sur Linkedin</a>
          </div>
        </div>
      </div>
    </div>

  <div :class="'container bg-secondary'">
    <div :class="'container py-5 px-0 p-lg-4 m-lg-auto'">
      <TitleTextButton
        :other="true"
        :title="true"
        :text="true"
        otherContent='<p class="subtitle component-other text-center">Présentation</p>'
        titleContent="Hervé aujourd'hui c'est..."
        textContent="En 2017, il est le cofondateur de la société <a href='https://www.sprint-project.com' target='_blank'>Sprint<i>Project</i></a>, la cellule d'open innovation mutualisée des acteurs de la filière de la supply chain. Il est également le fondateur de <a href='https://www.pitchandrise.co' target='_blank'>Pitch and Rise</a>, plateforme collaborative de veille humaine qui permet de suivre et d'évaluer en continu la progression des start-ups. Il conseille, accompagne et transmet son expérience de création de cellules d'innovation ouverte aux grandes organisations dans le cadre de leur démarche d'open innovation."
        otherClass="text-dark"
        titleClass="text-center"
        textClass="text-center"
      />
    </div>

    <div class="row flex-column flex-lg-row">
      <img src="../assets/img/qui1.png" :class="'px-0 col-lg-6 h-75 p-lg-0'" alt="" />
      <div class="bg-dark p-lg-0 col-lg-6 d-flex">
        <div :class="'container px-0 py-5 p-lg-4 m-lg-auto'">
          <TitleTextButton
            :other="true"
            :title="true"
            :text="true"
            :button="false"
            otherContent='<p class="subtitle text-white">Les débuts</p>'
            titleContent="Management des organisations en transition."
            textContent="Il débute sa carrière dans l'intégration des nouvelles technologies dans les entreprises, mais se spécialise très rapidement dans le management des organisations en périodes de transitions. Au sein de différentes sociétés, il pilote des projets de création, de développement et de restructuration d'activités."
            titleClass="text-white title-debuts"
            textClass="text-white spring-text"
          />
        </div>
      </div>
    </div>

    <div class="row flex-column flex-lg-row">
        <div class="bg-danger p-lg-0 col-lg-6 order-0 order-lg-0 d-flex">
          <div :class="'container py-5 px-0 p-lg-4 m-lg-auto'">
            <TitleTextButton
              :other="true"
              :title="true"
              :text="true"
              :button="false"
              otherContent='<p class="subtitle text-white">Création de az intiatys</p>'
              titleContent="La cellule d'essaimage externalisée des grands groupes"
              textContent="En 2001, il lance sa première activité de conseil en management opérationnel avant de créer en 2003 la société AZ Initiatys, dédiée au développement de l'entrepreneuriat par Essaimage. Ses clients sont de grandes groupes et administrations qui souhaitent favoriser la création d'entreprises par leurs collaborateurs. Pour leur compte, AZ Initiatys assure l'accueil et l'accompagnement de ces futurs entrepreneurs dans le développement de leurs activités innovantes. Il intervient également dans différents cursus de formation d'HEC, de l'Ecole Polytechnique et de l'Ecole Centrale Paris en lien avec l'entrepreneuriat. Il est membre de plusieurs jurys de financement de projets et d'entreprises. Dans le cadre de ses différentes missions, il a accompagné plus de 1 000 candidats entrepreneurs."
              titleClass="text-white"
              textClass="text-white"
            />
            <div class="d-flex text-white align-items-center">
            <div class="chiffre">+ 1 000</div>
            <p class="chiffre-info mx-3 my-auto text-white">entrepreneurs<br>accompagnés</p>
          </div>
          </div>
        </div>
        <img class="px-0 p-lg-0 col-lg-6 order-2 order-lg-1" src="../assets/img/img8.png" alt="" />
    </div>
    <div class="row flex-column flex-lg-row">
        <div :class="'container pt-5 px-0 pt-lg-4 m-lg-auto'">
          <TitleTextButton
            :title="true"
            titleContent="Du management des entreprises<br>de croissance à l'Open innovation"
            titleClass="text-center"
          />
        </div>
          <div class="p-lg-0 col-lg-6 order-0 order-lg-0 d-flex">
            <div :class="'container pt-2 pb-5 px-0 p-lg-4 m-lg-auto'">
              <p>En 2010, il crée un département dédié à l'accompagnement opérationnel des dirigeants de sociétés innovantes et à forte croissance. À ce titre, il dirige des projets de développement dans des domaines aussi variés que la robotique industrielle, la logistique, le transport, la relation client, l'automobile, les médias ou la distribution d'accessoires de mode...</p>
              <p>Depuis 2015, il conseille de grands groupes internationaux en matière d'Open innovation. Il s'agit de détecter, évaluer et opérer des rapprochements créateurs de valeur avec des start-ups innovantes et économiquement viables, offrant un potentiel de synergies avec eux.</p>
              <p>En 2016, il est désigné Auditeur de la 53ème Session Nationale Armement et Economie de Défense de <a href="https://www.ihedn.fr" target="_blank">L'IHEDN</a> (Institut des Hautes Etudes de Défense Nationale). Dans ce cadre, il est corédacteur et rapporteur du rapport Léonard sur l'opportunité d'une politique d'innovation ouverte au sein de l'écosystème de la sécurité et de la défense.</p>
            </div>
          </div>
          <img class="px-0 col-lg-6 order-2 order-lg-1 p-lg-4" src="../assets/img/inno.png" alt="" />
    </div>
  </div>

    <div :class="'bg-danger container bkg-img py-5 px-0 p-lg-4'">
      <img :src="require('../assets/img/espaces/unsplash.png')" alt="" />
      <TitleTextButton
        :other="false"
        :title="true"
        :text="true"
        titleContent="Ses engagements"
        textContent="Depuis 2017, il est Officier de la Réserve Citoyenne de la Marine Nationale. De 2017 à 2020, il est Cadre de Comité de l'IHEDN.<br> De 2017 à 2020, Il conseille le Ministère des Armées dans la mise en œuvre de la Cellule d'innovation Ouverte de l'Agence d'innovation de la Défense."
        titleClass="text-center text-white"
        textClass="pt-2 text-center text-white"
      />
    </div>

  </div>
</template>

<script>
import TitleTextButton from '../components/TitleTextButton'

export default {
  components: {
    TitleTextButton
  },

  mounted () {
    const bkgs = document.querySelectorAll('.bkg-img') // récupération de toutes les divs ou tu veux appliquer le bkg

    bkgs.forEach(bkg => { // boucle de toutes les divs
      const src = bkg.children[0].getAttribute('src') // récupération de src de l'img de la div
      bkg.children[0].style.display = 'none'// application du display none sur img
      bkg.style.background = "url('" + src + "') center center no-repeat" // application du bkg sur la div
      bkg.style.backgroundSize = 'cover' // aplication du cover de bkg sur la div
    })
  }
}

</script>

<style lang="scss" scoped>
.header-qui-sommes-nous{
  .bg-danger{
      position: relative;
      width: 100%;
      height: 86px;
      h2{
        font-size: 79px;
        line-height: 75px;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
    }
  }
}
.rect-rouge{
  width: 240px;
  height: 126px;
  position: absolute;
  top: 200px;
  left: -180px;
  opacity: 0.8;
}
</style>
